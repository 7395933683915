import groq from 'groq'

export const frameBuilderServicePricesObjectFragment = groq`
  "servicePrices": *[
    _type == "frameBuilderServicePrices" &&
    locale == $locale
  ][0],
`

export const frameBuilderProductServicePricesObjectFragment = groq`
  "servicePrices": *[
    _type == "frameBuilderServicePrices" &&
    locale == $locale
  ][0],
`
