import groq from 'groq'

import { frameBuilderImageFragment } from './image'

const frameBuilderGlassFragment = groq`
  ...,
  icon {
    ${frameBuilderImageFragment}
  },
`

const frameBuilderProductGlassFragment = groq`
  _id,
  sku,
  title,
  subtitle,
  description,
  price,
  discountPercent,
  weight,
  thickness,
`

export const frameBuilderGlassObjectFragment = groq`
  "glass": *[
    _type == "frameBuilderGlass" &&
    locale == $locale
  ]
    | order(order asc, price asc)
  {
    ${frameBuilderGlassFragment}
  },
`

export const frameBuilderProductGlassObjectFragment = groq`
  "glass": *[
    _type == "frameBuilderGlass" &&
    locale == $locale
  ]
    | order(order asc, price asc)
  {
    ${frameBuilderProductGlassFragment}
  },
`
