import groq from 'groq'

import { frameBuilderImageFragment } from './image'

const frameBuilderDefaultFrameFragment = groq`
  ...,
  previewImage {
    ${frameBuilderImageFragment}
  },
`

export const frameBuilderDefaultFrameObjectFragment = groq`
  "defaultFrame": *[
    _type == "frameBuilderDefaultFrame" &&
    locale == $locale
  ][0] {
    ${frameBuilderDefaultFrameFragment}
  },
`
