import groq from 'groq'

import { frameBuilderImageFragment } from './image'

export const frameBuilderPortableTextFragment = groq`
  ...,
  markDefs[] {
    ...,
    _type == "link" => {
      page->{
        _id,
        _type,
        slug,
      },
    },
  },
  _type == "frameBuilderFigure" => {
    ${frameBuilderImageFragment}
  },
`
