import groq from 'groq'

const frameBuilderStandardSizeFragment = groq`
  ...,
  customFramePrices[]->,
`

const frameBuilderProductStandardSizeFragment = groq`
  _id,
  width,
  height,
  customFramePrices[]->,
`

export const frameBuilderStandardSizeObjectFragment = groq`
  "standardSizes": *[
    _type == "frameBuilderStandardSize" &&
    locale == $locale
  ]
    | order(order asc)
  {
    ${frameBuilderStandardSizeFragment}
  },
`

export const frameBuilderProductStandardSizeObjectFragment = groq`
  "standardSizes": *[
    _type == "frameBuilderStandardSize" &&
    locale == $locale
  ]
    | order(order asc)
  {
    ${frameBuilderProductStandardSizeFragment}
  },
`
