import groq from 'groq'

import { frameBuilderImageFragment } from './image'

export const frameBuilderTextureFragment = groq`
  ...,
  icon {
    ${frameBuilderImageFragment}
  },
  image {
    ${frameBuilderImageFragment}
  },
  images[] {
    ...,
    image {
      ${frameBuilderImageFragment}
    },
  },
`

const frameBuilderProductTextureFragment = groq`
  _id,
  sku,
  title,
  availableFor[] {
    profile,
    price,
    discountPercent,
  },
`

export const frameBuilderTextureObjectFragment = groq`
  "textures": *[
    _type == "frameBuilderTexture" &&
    locale == $locale
  ]
    | order(order asc)
  {
    ${frameBuilderTextureFragment}
  },
`

export const frameBuilderProductTextureObjectFragment = groq`
  "textures": *[
    _type == "frameBuilderTexture" &&
    locale == $locale
  ]
    | order(order asc)
  {
    ${frameBuilderProductTextureFragment}
  },
`
