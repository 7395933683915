import groq from 'groq'

import { frameBuilderPortableTextFragment } from './content'

const frameBuilderExtraServiceFragment = groq`
  ...,
  description[] {
    ${frameBuilderPortableTextFragment}
  },
`

const frameBuilderProductExtraServiceFragment = groq`
  _id,
  sku,
  title,
  price,
  sizeBasedPrice,
`

export const frameBuilderExtraServiceObjectFragment = groq`
  "extraServices": *[
    _type == "frameBuilderExtraService" &&
    locale == $locale &&
    enabled == true
  ]
    | order(order asc)
  {
    ${frameBuilderExtraServiceFragment}
},
`

export const frameBuilderProductExtraServiceObjectFragment = groq`
  "extraServices": *[
    _type == "frameBuilderExtraService" &&
    locale == $locale &&
    enabled == true
  ]
    | order(order asc)
  {
    ${frameBuilderProductExtraServiceFragment}
  },
`
