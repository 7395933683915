import groq from 'groq'

import { frameBuilderImageFragment } from './image'
import { frameBuilderProfileFragment } from './profile'

const frameBuilderPassepartoutSizeFragment = groq`
  ...,
  icon {
    ${frameBuilderImageFragment}
  },
`

const frameBuilderPassepartoutColorFragment = groq`
  ...,
  availableFor[]->{
    ${frameBuilderProfileFragment}
  },
`

const frameBuilderProductPassepartoutSizeFragment = groq`
  _id,
  sku,
  title,
  subtitle,
  description,
  size,
  discountPercent,
`

const frameBuilderProductPassepartoutColorFragment = groq`
  _id,
  sku,
  title,
  availableFor[],
  maximumWidth,
  maximumHeight,
`

export const frameBuilderPassepartoutSizeObjectFragment = groq`
  "passepartoutSizes": *[
    _type == "frameBuilderPassepartoutSize" &&
    locale == $locale
  ]
    | order(order asc, size asc)
  {
    ${frameBuilderPassepartoutSizeFragment}
  },
`

export const frameBuilderPassepartoutColorObjectFragment = groq`
  "passepartoutColors": *[
    _type == "frameBuilderPassepartoutColor" &&
    locale == $locale
  ]
    | order(order asc)
  {
    ${frameBuilderPassepartoutColorFragment}
  },
`

export const frameBuilderProductPassepartoutSizeObjectFragment = groq`
  "passepartoutSizes": *[
    _type == "frameBuilderPassepartoutSize" &&
    locale == $locale
  ]
    | order(order asc, size asc)
  {
    ${frameBuilderProductPassepartoutSizeFragment}
  },
`

export const frameBuilderProductPassepartoutColorObjectFragment = groq`
  "passepartoutColors": *[
    _type == "frameBuilderPassepartoutColor" &&
    locale == $locale
  ]
    | order(order asc)
  {
    ${frameBuilderProductPassepartoutColorFragment}
  },
`
