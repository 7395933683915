import groq from 'groq'

import { imageFragment } from './image'

export const blogAuthorFragment = groq`
  name,
  slug,
  photo {
    ${imageFragment}
  },
`

export const blogCategoryFragment = groq`
  name,
  slug,
`

export const blogPostWithoutBodyFragment = groq`
  _id,
  _createdAt,
  _updatedAt,
  date,
  title,
  slug,
  excerpt,
  author->{
    ${blogAuthorFragment}
  },
  categories[]->{
    ${blogCategoryFragment}
  },
  coverImage {
    ${imageFragment}
  },
`

export const blogSettingsQuery = groq`
  *[
    _type == "blogSettings" &&
    locale == $locale
  ][0]
`
